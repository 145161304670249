.python {
    background-color: #33a8c7 !important;
}
.javascript{
    background-color: #f77976 !important;
}
.kotlin{
    background-color: #ffab00 !important;
}
.php{
    background-color: #0077b6 !important;
}
.java{
    background-color: green !important;
}
.A_dot_net{
    background-color: #f1faee !important;
}
.basic{
    background-color: #e63946 !important;
}
.c{
    background-color: #a8dadc !important;
}
.C_plus_plus{
background-color: #a5a58d !important;
}
.C_sharp{
background-color: #cb997e!important;
}
.ruby{
background-color: #ef476f !important;
}

.HTML{
background-color: #a5a58d!important;
}

.HTML5{
background-color: #118ab2!important;
}

.CSS3{
background-color: #e63946 !important;
}

.react{
background-color: #023e8a !important;
}


.node_js{
background-color: #00f5d4 !important;
}

.django{
background-color: #f52b88!important;
}

.bootstrap4{
background-color: #ffba08 !important;
}

.dreamweaver{
background-color: #6a040f !important;
}

.jquery{
background-color: #00bbf9 !important;
}
.logo{
background-color: #073b4c !important;
}
.mysql{
background-color: #4d908e !important;
}
.sql{
background-color: #f3722c !important;
}
.pascal{
background-color: #cd9777 !important;
}
.perl{
background-color: #d69f7e !important;
}
.sql{
background-color: #90be6d !important;
}

.laravel{
background-color: #fee440 !important;
}
.swift{
background-color: #f28482 !important;
}
.postgresql{
background-color: #f15bb5 !important;
}


.bootstrap5{
background-color: #f28482 !important;
}
.fortan{
background-color: #b8f2e6 !important;
}
.J_plus_plus{
background-color: #5e6472 !important;
}

.matlab{
background-color: #9b5de5 !important;
}

.KRL{
background-color: #d9ae94 !important;
}

.live_code{
background-color: #dee2e6 !important;
}

.live_script{
background-color: #495057 !important;
}

.lotus_script{
background-color: #e5383b !important;
}

.M_four{
background-color: #d8e2dc !important;
}

.M_plus_plus{
background-color: #b1a7a6 !important;
}
.mercury{
background-color: #161a1d !important;
}
.ml{
background-color: #cbf3f0 !important;
}
.R_plus_plus{
background-color: #723d46 !important;
}

.rust{
background-color: #e26d5c !important;
}

.groovy{
background-color: #e26d5c !important;
}
.xml{
background-color: #ffab00 !important;
}