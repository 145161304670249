.navColor{
    background-color: #127EA6;
    color: white !important;
}
.navLink{
    color: white !important;
    margin: 0px 20px;
    font-weight: bolder;
}

.isActive{
    color: #0658B3 !important;
}

.navBrand{
    font-size: 28px;
    color: white;
}

.navBrand:hover{
    color: #fff;
}

.navbar{
    padding: 0 !important;
}