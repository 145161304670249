.jumboleft{
    text-align: left;
    background-image: url('../images/hero_finally.jpg') !important;
    background-size: cover;
    color: white;
}

.askQuestion{
    background-color: #127EA6 !important;
}

.askQuestion:hover {
    background-color: #40b3de !important;
    border: none;
}

.postAnswerButton {
    width: 15% !important;
}


.iconHovered:hover {
    cursor: pointer;
}