@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Source Code Pro', monospace;
  /* background: radial-gradient(#e5e5e5, #ffff, #e5e5e5); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* added styles */

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
button[type="submit"] {
    background-color: #127EA6 !important;
    color: white
}

button[type="submit"]:hover {
    background-color: #127EA6 !important;
    color: white;
    box-shadow: 5px 20px 10px 1px rgba(0, 0, 0, 0.253) !important;
}
.header_navColor__3ZUtl{
    background-color: #127EA6;
    color: white !important;
}
.header_navLink__1gXZR{
    color: white !important;
    margin: 0px 20px;
    font-weight: bolder;
}

.header_isActive__31N_X{
    color: #0658B3 !important;
}

.header_navBrand__1JcGs{
    font-size: 28px;
    color: white;
}

.header_navBrand__1JcGs:hover{
    color: #fff;
}

.header_navbar__3Uska{
    padding: 0 !important;
}
.footer_footer__10r_j{
    background-color: #127EA6;
    color: white;
}

.footer_listType__1rsSQ{
    list-style: none;
}

.footer_socialIcons__AE-vd{
    display: flex;
    font-size: 40px;
    justify-content: center;
}

.footer_footer2Links__1z6Vp{
    display: flex;
    justify-content: center;
}

.footer_arrowIcon__1OSPY{
    /* padding-top: 5px; */
   margin-right: 40px;
   font-size: 20px;
}
.about_teamImg__1ddYJ {
    width: 200px;
    height: 200px;
    border-radius: 100%;
}

.about_iconSize__DbH6b {
    font-size: 30px;
}
.hero_jumboleft__18gab{
    text-align: left;
    background-image: url(/static/media/hero_finally.04fa0d2b.jpg) !important;
    background-size: cover;
    color: white;
}

.hero_askQuestion__3hLYI{
    background-color: #127EA6 !important;
}

.hero_askQuestion__3hLYI:hover {
    background-color: #40b3de !important;
    border: none;
}

.hero_postAnswerButton__Kxvet {
    width: 15% !important;
}


.hero_iconHovered__3baBo:hover {
    cursor: pointer;
}
.question_shadowEffect__rovKN:hover{
    box-shadow: 5px 20px 10px 1px rgba(0, 0, 0, 0.253) !important;
}

.python {
    background-color: #33a8c7 !important;
}
.javascript{
    background-color: #f77976 !important;
}
.kotlin{
    background-color: #ffab00 !important;
}
.php{
    background-color: #0077b6 !important;
}
.java{
    background-color: green !important;
}
.A_dot_net{
    background-color: #f1faee !important;
}
.basic{
    background-color: #e63946 !important;
}
.c{
    background-color: #a8dadc !important;
}
.C_plus_plus{
background-color: #a5a58d !important;
}
.C_sharp{
background-color: #cb997e!important;
}
.ruby{
background-color: #ef476f !important;
}

.HTML{
background-color: #a5a58d!important;
}

.HTML5{
background-color: #118ab2!important;
}

.CSS3{
background-color: #e63946 !important;
}

.react{
background-color: #023e8a !important;
}


.node_js{
background-color: #00f5d4 !important;
}

.django{
background-color: #f52b88!important;
}

.bootstrap4{
background-color: #ffba08 !important;
}

.dreamweaver{
background-color: #6a040f !important;
}

.jquery{
background-color: #00bbf9 !important;
}
.logo{
background-color: #073b4c !important;
}
.mysql{
background-color: #4d908e !important;
}
.sql{
background-color: #f3722c !important;
}
.pascal{
background-color: #cd9777 !important;
}
.perl{
background-color: #d69f7e !important;
}
.sql{
background-color: #90be6d !important;
}

.laravel{
background-color: #fee440 !important;
}
.swift{
background-color: #f28482 !important;
}
.postgresql{
background-color: #f15bb5 !important;
}


.bootstrap5{
background-color: #f28482 !important;
}
.fortan{
background-color: #b8f2e6 !important;
}
.J_plus_plus{
background-color: #5e6472 !important;
}

.matlab{
background-color: #9b5de5 !important;
}

.KRL{
background-color: #d9ae94 !important;
}

.live_code{
background-color: #dee2e6 !important;
}

.live_script{
background-color: #495057 !important;
}

.lotus_script{
background-color: #e5383b !important;
}

.M_four{
background-color: #d8e2dc !important;
}

.M_plus_plus{
background-color: #b1a7a6 !important;
}
.mercury{
background-color: #161a1d !important;
}
.ml{
background-color: #cbf3f0 !important;
}
.R_plus_plus{
background-color: #723d46 !important;
}

.rust{
background-color: #e26d5c !important;
}

.groovy{
background-color: #e26d5c !important;
}
.xml{
background-color: #ffab00 !important;
}
.lds-hourglass {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid #127EA6;
    border-color: #127EA6 transparent #127EA6 transparent;
    -webkit-animation: lds-hourglass 1.2s infinite;
            animation: lds-hourglass 1.2s infinite;
  }
  @-webkit-keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
              animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
              animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }
  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
              animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
              animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }
.page-item.active .page-link {
    background-color: #127EA6 !important;
    border: none !important;
}
.formStyles {
    width: 500px !important;
}
.user_socialMediaLogin__ZF_NN{
    text-align: center;
    cursor: pointer;
    padding: 10px;
    color: white;
    background: #e33b34;
}
